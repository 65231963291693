import React from 'react'
import {
  Section,
  Grid,
  ImageBasic,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper
} from 'inno-components'
import { withNamespaces } from 'react-i18next'
import { StyleSheet, css } from 'aphrodite'
import Helmet from 'react-helmet'

import Config from '../../Config'
import Classes from '../../styles/classes'

const styles = {
  image: {
    borderRadius: '0.5em'
  },
  bold: {
    fontWeight: 'bold'
  },
  tableCellHead: {
    backgroundColor: Config.colors.primary,
    color: '#fff',
    fontWeight: 'bold'
  },
  tableCell: {
    border: 'solid 1px #ccc',
    borderCollapse: 'collapse'
  },
  green: {
    color: Config.colors.secondary
  },
  caption: {
    textAlign: 'center',
    marginTop: '1.5em',
    mariginBottom: '4em',
    fontStyle: 'italic',
    fontSize: '0.8em',
    color: '#777'
  }
}
const Accredidations = ({ t }) => (
  <React.Fragment>
    <Helmet
      title={t('head.title')}
      meta={[
        {
          name: 'description',
          content: t('head.meta-description')
        }
      ]}
    />
    <Section className={css(Classes.section)} bgColor="#fff">
      <h2 className={css(Classes.h2)}>{t('sections.0.titre')}</h2>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={16}>
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.0.paragraphes.0')
              }}
            />
            <ul>
              <li>{t('sections.0.paragraphes.1')}</li>
              <li>{t('sections.0.paragraphes.2')}</li>
              <li>{t('sections.0.paragraphes.3')}</li>
              <li>{t('sections.0.paragraphes.4')}</li>
              <li>{t('sections.0.paragraphes.5')}</li>
            </ul>
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.0.paragraphes.6')
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={6}>
          <Grid.Column>
            <div className={css(Classes.imageContainer)}>
              <ImageBasic
                src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/images/pages/image007.png"
                className={css(Classes.image)}
              />
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className={css(Classes.imageContainer)}>
              <ImageBasic
                src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/images/pages/image008.png"
                className={css(Classes.image)}
              />
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className={css(Classes.imageContainer)}>
              <ImageBasic
                src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/images/pages/image009.gif"
                className={css(Classes.image)}
              />
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className={css(Classes.imageContainer)}>
              <ImageBasic
                src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/images/pages/image010.jpg"
                className={css(Classes.image)}
              />
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className={css(Classes.imageContainer)}>
              <ImageBasic
                src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/images/pages/image011.png"
                className={css(Classes.image)}
              />
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className={css(Classes.imageContainer)}>
              <ImageBasic
                src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/images/pages/image012.jpg"
                className={css(Classes.image)}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Section>
    <Section className={css(Classes.section)} bgColor="#fff">
      <h2 className={css(Classes.h2)}>{t('sections.1.titre')}</h2>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={16}>
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.1.paragraphes.0')
              }}
            />
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.1.paragraphes.1')
              }}
            />
            <p
              className={css(Classes.paragraph, Classes.bold)}
              dangerouslySetInnerHTML={{
                __html: t('sections.1.paragraphes.2')
              }}
            />
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.1.paragraphes.3')
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column width={10}>
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={Object.assign({}, styles.tableCellHead, styles.tableCell)}>
                      {t('sections.1.tableaux.0.titres.0')}
                    </TableCell>
                    <TableCell style={Object.assign({}, styles.tableCellHead, styles.tableCell)}>
                      {t('sections.1.tableaux.0.titres.1')}
                    </TableCell>
                    <TableCell style={Object.assign({}, styles.tableCellHead, styles.tableCell)}>
                      {t('sections.1.tableaux.0.titres.2')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell style={styles.tableCell} rowSpan={3}>
                      {t('sections.1.tableaux.0.lignes.0.case1')}
                    </TableCell>
                    <TableCell style={styles.tableCell}>
                      {t('sections.1.tableaux.0.lignes.0.case2.0')}
                    </TableCell>
                    <TableCell style={styles.tableCell}>
                      {t('sections.1.tableaux.0.lignes.0.case3.0')}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={styles.tableCell}>
                      {t('sections.1.tableaux.0.lignes.0.case2.1')}
                    </TableCell>
                    <TableCell style={styles.tableCell}>
                      {t('sections.1.tableaux.0.lignes.0.case3.1')}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={styles.tableCell}>
                      {t('sections.1.tableaux.0.lignes.0.case2.2')}
                    </TableCell>
                    <TableCell style={styles.tableCell}>
                      {t('sections.1.tableaux.0.lignes.0.case3.2')}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={styles.tableCell}>
                      <span className={css(Classes.bold)}>
                        {t('sections.1.tableaux.0.lignes.1.case1')}
                      </span>
                    </TableCell>
                    <TableCell style={styles.tableCell} className={css(Classes.bold)}>
                      <span className={css(Classes.bold)}>
                        {t('sections.1.tableaux.0.lignes.1.case2')}
                      </span>
                    </TableCell>
                    <TableCell style={Object.assign({}, styles.bold, styles.green)}>
                      {t('sections.1.tableaux.0.lignes.1.case3')}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
            <p className={css(Classes.tableCaption)}>{t('sections.1.tableaux.0.description')}</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column width={10}>
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={Object.assign({}, styles.tableCellHead, styles.tableCell)}>
                      {t('sections.1.tableaux.1.titres.0')}
                    </TableCell>
                    <TableCell style={Object.assign({}, styles.tableCellHead, styles.tableCell)}>
                      {t('sections.1.tableaux.1.titres.1')}
                    </TableCell>
                    <TableCell style={Object.assign({}, styles.tableCellHead, styles.tableCell)}>
                      {t('sections.1.tableaux.1.titres.2')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell style={styles.tableCell} rowSpan={3}>
                      {t('sections.1.tableaux.1.lignes.0.case1')}
                    </TableCell>
                    <TableCell style={styles.tableCell}>
                      {t('sections.1.tableaux.1.lignes.0.case2.0')}
                    </TableCell>
                    <TableCell style={styles.tableCell}>
                      {t('sections.1.tableaux.1.lignes.0.case3.0')}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={styles.tableCell}>
                      {t('sections.1.tableaux.1.lignes.0.case2.1')}
                    </TableCell>
                    <TableCell style={styles.tableCell}>
                      {t('sections.1.tableaux.1.lignes.0.case3.1')}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={styles.tableCell}>
                      {t('sections.1.tableaux.1.lignes.0.case2.2')}
                    </TableCell>
                    <TableCell style={styles.tableCell}>
                      {t('sections.1.tableaux.1.lignes.0.case3.2')}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={styles.tableCell}>
                      <span className={css(Classes.bold)}>
                        {t('sections.1.tableaux.1.lignes.1.case1')}
                      </span>
                    </TableCell>
                    <TableCell style={styles.tableCell} className={css(Classes.bold)}>
                      <span className={css(Classes.bold)}>
                        {t('sections.1.tableaux.1.lignes.1.case2')}
                      </span>
                    </TableCell>
                    <TableCell
                      style={styles.tableCell}
                      style={Object.assign({}, styles.bold, styles.green)}
                    >
                      {t('sections.1.tableaux.1.lignes.1.case3')}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
            <p className={css(Classes.tableCaption)}>{t('sections.1.tableaux.1.description')}</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.1.paragraphes.4')
              }}
            />
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.1.paragraphes.5')
              }}
            />
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.1.paragraphes.6')
              }}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row centered>
          <Grid.Column width={10}>
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={Object.assign({}, styles.tableCellHead, styles.tableCell)}>
                      {t('sections.1.tableaux.2.titres.0')}
                    </TableCell>
                    <TableCell style={Object.assign({}, styles.tableCellHead, styles.tableCell)}>
                      {t('sections.1.tableaux.2.titres.1')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell style={styles.tableCell}>
                      {t('sections.1.tableaux.2.lignes.0.case1')}
                    </TableCell>
                    <TableCell style={styles.tableCell}>
                      {t('sections.1.tableaux.2.lignes.0.case2')}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={styles.tableCell}>
                      <span className={css(Classes.bold)}>
                        {t('sections.1.tableaux.2.lignes.1.case1')}
                      </span>
                    </TableCell>
                    <TableCell
                      style={styles.tableCell}
                      style={Object.assign({}, styles.bold, styles.green)}
                    >
                      {t('sections.1.tableaux.2.lignes.1.case2')}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
            <p className={css(Classes.tableCaption)}>{t('sections.1.tableaux.2.description')}</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column width={10}>
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={Object.assign({}, styles.tableCellHead, styles.tableCell)}>
                      {t('sections.1.tableaux.3.titres.0')}
                    </TableCell>
                    <TableCell style={Object.assign({}, styles.tableCellHead, styles.tableCell)}>
                      {t('sections.1.tableaux.3.titres.1')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell style={styles.tableCell}>
                      {t('sections.1.tableaux.3.lignes.0.case1')}
                    </TableCell>
                    <TableCell style={styles.tableCell}>
                      {t('sections.1.tableaux.3.lignes.0.case2')}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={styles.tableCell}>
                      <span className={css(Classes.bold)}>
                        {t('sections.1.tableaux.3.lignes.1.case1')}
                      </span>
                    </TableCell>

                    <TableCell
                      style={styles.tableCell}
                      style={Object.assign({}, styles.bold, styles.green)}
                    >
                      {t('sections.1.tableaux.3.lignes.1.case2')}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
            <p className={css(Classes.tableCaption)}>{t('sections.1.tableaux.3.description')}</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.1.paragraphes.7')
              }}
            />

            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.1.paragraphes.8')
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={5}>
          <Grid.Column>
            <div className={css(Classes.imageContainer)}>
              <ImageBasic
                src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/images/pages/image014.png"
                className={css(Classes.image)}
              />
              <p className={css(Classes.imageCaption)}>{t('sections.1.images.0')}</p>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className={css(Classes.imageContainer)}>
              <ImageBasic
                src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/images/pages/image015.png"
                className={css(Classes.image)}
              />
              <p className={css(Classes.imageCaption)}>{t('sections.1.images.1')}</p>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className={css(Classes.imageContainer)}>
              <ImageBasic
                src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/images/pages/image016.png"
                className={css(Classes.image)}
              />
              <p className={css(Classes.imageCaption)}>{t('sections.1.images.2')}</p>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className={css(Classes.imageContainer)}>
              <ImageBasic
                src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/images/pages/image017.png"
                className={css(Classes.image)}
              />
              <p className={css(Classes.imageCaption)}>{t('sections.1.images.3')}</p>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className={css(Classes.imageContainer)}>
              <ImageBasic
                src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/images/pages/image018.png"
                className={css(Classes.image)}
              />
              <p className={css(Classes.imageCaption)}>{t('sections.1.images.4')}</p>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Section>
    <Section className={css(Classes.section)}>
      <h2 className={css(Classes.h2)}>{t('sections.2.titre')}</h2>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={16}>
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.2.paragraphes.0')
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column width={2}>
            <div className={css(Classes.imageContainer)}>
              <ImageBasic
                src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/images/pages/image019.png"
                className={css(Classes.image)}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.2.paragraphes.1')
              }}
            />
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.2.paragraphes.2')
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column width={4}>
            <div className={css(Classes.imageContainer)}>
              <ImageBasic
                src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/images/pages/image020.png"
                className={css(Classes.image)}
                size="big"
              />
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.2.paragraphes.3')
              }}
            />
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.2.paragraphes.4')
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column width={2}>
            <div className={css(Classes.imageContainer)}>
              <ImageBasic
                src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/images/pages/image021.gif"
                className={css(Classes.image)}
              />
            </div>
          </Grid.Column>
          <Grid.Column width={4}>
            <div className={css(Classes.imageContainer)}>
              <ImageBasic
                src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/images/pages/image022.png"
                className={css(Classes.image)}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.2.paragraphes.5')
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column width={4}>
            <div className={css(Classes.imageContainer)}>
              <ImageBasic
                src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/images/pages/image023.png"
                className={css(Classes.image)}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <p
              className={css(Classes.paragraph)}
              dangerouslySetInnerHTML={{
                __html: t('sections.2.paragraphes.6')
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column width={2}>
            <div className={css(Classes.imageContainer)}>
              <ImageBasic
                src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/raypath/images/pages/image024.jpg"
                className={css(Classes.image)}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Section>
  </React.Fragment>
)

export default withNamespaces('accreditations')(Accredidations)
