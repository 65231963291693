import React from 'react'
import { withNamespaces } from 'react-i18next'
import { compose, pure } from 'recompose'
import SetLanguageFromPage from '../../config/i18n/SetLanguageFromPage'

import SecondaryLayout from '../../components/layouts/SecondaryLayout'
import Accreditations from '../../components/methode-raypath/Accreditations'

const AccreditationsPage = ({ t }) => (
  <SecondaryLayout subtitle={t('titre')} page="ComponentMethodeRaypathAccreditation">
    <Accreditations />
  </SecondaryLayout>
)

const echance = compose(
  pure,
  SetLanguageFromPage(),
  withNamespaces('accreditations')
)

export default echance(AccreditationsPage)
